import { Button, Modal } from "antd";
import React from "react";
import ReactPlayer from "react-player";

const Player = ({ url }) => {
  console.log(url);
  const [playing, setPlaying] = React.useState(false);
  return (
    <div>
      <Button type="primary" onClick={() => setPlaying(!playing)}>
        Play
      </Button>
      <Modal footer={null} open={playing} onCancel={() => setPlaying(!playing)}>
        <div
          style={{
            aspectRatio: "16/9",
            width: "100%",
          }}
        >
          <ReactPlayer
            url={url && url?.includes("https://") ? url : `https://${url}`}
            width={"100%"}
            height={"100%"}
            controls
          />
        </div>
      </Modal>
    </div>
  );
};

export default Player;
